<template>
    <sidebar-general
        ref="sidebarVincularProfesional"
        title="Vincular profesional"
        aceptar="Vincular"
    >
        <b-container class="pt-1">
            <div>
                <label for="profesional">Profesional</label>
                <v-select
                    :options="['Manuel', 'Oscar', 'Gian']"
                    id="profesional"
                    placeholder="Escribe el nombre"
                ></v-select>
            </div>
            <div class="pt-1">
                <label for="fechaingreso">Fecha de ingreso</label>
                <b-form-datepicker
                    placeholder="Fecha"
                    id="fechaingreso"
                ></b-form-datepicker>
            </div>
        </b-container>
    </sidebar-general>
</template>
<script>
import { ref } from "@vue/composition-api";
export default {
    setup(props, context) {
        function toggle() {
            context.refs.sidebarVincularProfesional.toggle();
        }
        return { toggle };
    },
};
</script>
